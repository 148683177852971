<template>
  <div
    class="
      w-full
      text-white
      rounded-full
      shadow-lg
      relative
      z-10
      whitespace-nowrap
    "
    ref="tabsContainer"
  >
    <div class="m-tabs__track relative">
      <ul
        class="
          m-tabs__items
          px-16
          flex
          justify-center
          items-center
          z-10
          relative
        "
      >
        <li
          v-for="{ action, id, link, title } in tabs"
          :class="activeTab === id ? 'is-active' : ''"
          :id="`tab-${id}`"
          :key="id"
          class="text-center"
        >
          <router-link
            class="
              py-4
              px-6
              block
              font-bold
              transition-all
              delay-150
              duration-300
              ease-in-out
            "
            :class="activeTab === id ? activeTextClass : ''"
            :to="!action ? link : $route.path"
            @click="
              () =>
                !action
                  ? setSpotPosition(id)
                  : (() => {
                      setSpotPosition(id);
                      link();
                    })()
            "
            :title="title"
          >
            <span v-html="title" />
          </router-link>
        </li>
      </ul>
      <div
        class="
          m-tabs__spot
          absolute
          h-full
          top-0
          flex
          items-center
          pointer-events-none
          opacity-0
          transition-all
          delay-150
          duration-300
          ease-in-out
          before:bg-white
          before:rounded-full
          before:h-5/6
          before:w-full
          before:shadow-md
        "
        ref="spot"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import $ from "jquery";

export default {
  name: "Tabs",
  props: {
    activeTab: {
      type: String,
      required: true,
    },
    activeTextClass: {
      type: String,
      required: false,
      default: "",
    },
    onChangeTab: {
      type: Function,
      required: true,
    },
    tabs: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const spot = ref(null);
    const tabsContainer = ref(null);

    onMounted(() => {
      setTimeout(() => setSpotPosition(props.activeTab), 500);
      $(window).on("resize", () => setSpotPosition(props.activeTab));
    });

    const setSpotPosition = (tabId) => {
      props.onChangeTab(tabId);

      const $tabsContainer = $(tabsContainer.value);

      if ($tabsContainer.length) {
        const $activeTab = $tabsContainer.find(`#tab-${tabId}`);
        let activeTabPosition = $activeTab.position().left;

        if ($activeTab.is(":first-of-type") || $activeTab.is(":last-of-type")) {
          activeTabPosition += 4;
        }

        $(spot.value)
          .css({ left: `${activeTabPosition}px` })
          .width($activeTab.width() - 8)
          .addClass("!opacity-100");

        $tabsContainer
          .stop()
          .animate({ scrollLeft: activeTabPosition - 4 }, 500, "swing");
      }
    };

    return {
      setSpotPosition,
      spot,
      tabsContainer,
    };
  },
};
</script>
