<template>
  <header
    class="flex fixed z-30 top-0 left-0 justify-end w-full mb-12 lg:mb-20"
  >
    <Nav />
  </header>
</template>

<script>
import { provide, ref, watch } from "vue";
import { useRouter } from "vue-router";
import useLockScroll from "@/composables/useLockScroll";
import Nav from "@/components/organisms/Nav.vue";

export default {
  name: "Header",
  components: {
    Nav,
  },
  setup() {
    const router = useRouter();
    const menuOpen = ref(false);
    const scroll = useLockScroll();
    const menuItems = [
      {
        icon: require("@/assets/novo/home.svg"),
        link: "/",
        linkClass: "bg-[#023665]",
        spanClass: "text-[#023665]",
        title: "Home",
      },
      {
        desktoptitle: "Sobre o <br/> Programa RAStrear",
        icon: require("@/assets/novo/dna-helix-1.svg"),
        link: "/o-programa",
        linkClass: "bg-[#0063AD]",
        spanClass: "text-[#0063AD]",
        title: "Sobre o Programa RAStrear",
      },
    ];

    function toggleMenu() {
      menuOpen.value = !menuOpen.value;

      window.innerWidth <= 767 && menuOpen.value
        ? scroll.lock()
        : scroll.unlock();
    }

    watch(router.currentRoute, () => (menuOpen.value = false));

    provide("menuItems", menuItems);
    provide("menuOpen", menuOpen);
    provide("toggleMenu", toggleMenu);
  },
};
</script>
