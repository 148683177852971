<template>
  <Page
    class="bg-[#013C42] bg-intestino bg-intestino-posicao bg-intestino-tamanho before:hidden before:h-screen before:w-screen before:fixed before:pointer-events-none before:top-0 before:left-0 md:before:block md:bg-intestino-posicao-md md:bg-intestino-tamanho-md"
    contact
  >
    <div class="flex flex-col justify-center">
      <div class="text-center mb-5 mt-32 md:mt-8">
        <inline-svg
          class="w-44 inline-block align-top md:w-72 xl:w-96"
          :src="require('@/assets/novo/logo-rastrear-colorretal.svg')"
          title="Programa RAStrear Logo"
        />
      </div>
      <section class="mb-8 md:px-3">
        <BigButton />
      </section>
      <InternalNav />
      <div class="-mt-6">
        <router-view />
      </div>
    </div>
  </Page>
</template>

<script>
import BigButton from "@/components/molecules/BigButton.vue";
import InternalNav from "@/components/organisms/InternalNav.vue";
import Page from "@/components/atoms/Page.vue";
import useInternalNav from "@/composables/useInternalNav";
import { inject, provide } from "vue";
import useRedirect from "@/composables/useRedirect";
import useGetLink from "@/composables/useGetLink";

export default {
  components: { BigButton, InternalNav, Page },
  setup() {
    const redirect = inject("redirect");
    const { link } = useGetLink({ path: "colorretal" });
    const menuColor = "bg-[#09575F]";
    const menuTextColor = "text-[#09575F]";
    const menuItems = [
      {
        color: "bg-[#03A3B6]",
        id: "sobreoprogramacolorretal",
        title: "O Programa",
        link: { name: "SobreOProgramaColorretal" },
      },
      {
        action: true,
        id: "solicitar-exames",
        color: "bg-[#0192A3]",
        title: "Solicitação de Exames e Resultados",
        link: () => useRedirect({ link, redirect }),
      },
      {
        id: "orientacoescolorretal",
        color: "bg-[#008291]",
        title: "Orientações",
        link: { name: "OrientacoesColorretal" },
      },
      {
        id: "regulamentoedocumentoscolorretal",
        color: "bg-[#007481]",
        title: "Regulamento",
        link: { name: "RegulamentoEDocumentosColorretal" },
      },
      {
        id: "bempertodoseuamanha",
        color: "bg-[#01636E]",
        title: "Programa BemPerto®",
        link: { name: "BemPertoDoSeuAmanha" },
      },
    ];
    const contactInfo =
      "Atendimento de segunda à sexta das 8h às 18h, exceto feriados nacionais.";
    const phoneClass = "text-[#013C40] hover:text-[#09575F]";
    const phoneNumber = "0800-940-0113";
    const privacyPoliceLink = { name: "PoliticaDePrivacidadeColorretal" };

    useInternalNav({
      menuColor,
      menuItems,
      menuTextColor,
    });

    provide("contactInfo", contactInfo);
    provide("phoneClass", phoneClass);
    provide("phoneNumber", phoneNumber);
    provide("privacyPoliceLink", privacyPoliceLink);
  },
};
</script>
