const useRedirect = ({ link, redirect }) => {
  redirect.value = !redirect.value;

  setTimeout(() => {
    window.open(link, "_blank");

    redirect.value = false;
  }, 2500);
};

export default useRedirect;
