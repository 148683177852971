<template>
  <Header />
  <router-view />
  <MRedirect />
  <MLGPD />
</template>

<script>
import Header from "@/components/organisms/Header.vue";
import MLGPD from "@/components/molecules/LGPD.vue";
import MRedirect from "@/components/molecules/Redirect.vue";
import { computed, provide, ref } from "vue";

export default {
  name: "App",
  components: { Header, MLGPD, MRedirect },
  setup() {
    const redirect = ref(false);
    const isPrerendering = computed(() => !!window.__PRERENDER_INJECTED);

    provide("redirect", redirect);
    provide("isPrerendering", isPrerendering);
  },
};
</script>
