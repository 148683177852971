<template>
  <div
    class="
      o-contact
      bg-white
      shadow-xl
      rounded-full
      px-6
      py-0
      flex
      items-center
      justify-between
      h-[84px]
      md:max-w-sm md:mx-auto md:py-4 md:h-[104px]
      lg:max-w-[32rem] lg:justify-center
    "
  >
    <inline-svg
      aria-label="Contato"
      class="w-12 m-0 md:w-14 lg:w-16"
      :src="require('@/assets/contact.svg')"
      title="Contato"
    />
    <div
      class="
        o-contact__info
        text-center text-lg
        -mt-2
        md:flex-auto md:ml-4 md:mt-0
        xl:text-xl
      "
    >
      <address>
        <a
          class="
            text-3xl
            font-bold
            block
            not-italic
            leading-none
            md:text-4xl
            xl:text-5xl
          "
          :class="phoneClass"
          :href="`tel:+55${phoneNumber.replace(/\D+/g, '')}`"
          title="Telefone Programa RAStrear"
        >
          {{ phoneNumber }}
        </a>
      </address>
      <p class="text-[11px] leading-tight">
        {{ contactInfo }}
      </p>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";

export default {
  name: "Contact",
  setup() {
    const contactInfo = inject("contactInfo");
    const phoneClass = inject("phoneClass");
    const phoneNumber = inject("phoneNumber");

    return { contactInfo, phoneClass, phoneNumber };
  },
};
</script>
