<template>
  <footer
    class="o-footer bottom-0 pb-12 md:pb-20 lg:pb-7 bg-white text-primary pt-1"
    id="footer"
  >
    <BackToTop />
    <OContact v-if="contact" class="relative mx-4 z-20 -mt-9" />
    <div
      class="o-footer__logos m-auto text-center pb-0"
      :class="!contact ? 'pt-8' : 'pt-10'"
    >
      <inline-svg
        aria-label="Amgen"
        class="align-middle inline-block w-28 mr-6"
        :src="require('@/assets/amgen.svg')"
        title="Amgen"
      />
      <ALogo
        class="align-middle inline-block w-28 ml-6"
        title="Programa RAStrear"
      />
    </div>
    <div class="text-center text-[#C5C5C5]">
      <div class="mt-2">
        <a
          v-if="!privacyPoliceLink"
          class="mr-3 hover:text-[#4699F2]"
          href="https://www.amgen.com.br/privacy-statement/"
          title="Política de Privacidade Amgen"
          target="_blank"
        >
          <u>Política de Privacidade</u>
        </a>
        <router-link
          v-else
          class="hover:text-[#4699F2]"
          :to="privacyPoliceLink"
          title="Política de Privacidade"
        >
          <u>Política de Privacidade</u>
        </router-link>
        <span> | </span>
        <router-link
          class="hover:text-[#4699F2]"
          :to="{ name: 'TermosDeUso' }"
          title="Termos de uso Amgen"
        >
          <u>Termos de uso</u>
        </router-link>
      </div>
      <div class="text-[11px] mt-4">
        © 2018 - {{ new Date().getFullYear() }} Amgen Inc. Todos os direitos
        reservados.
      </div>
      <div class="text-[11px]">
        SC-BRA-AMG 510-00093 – Aprovado em Agosto/2022.
      </div>
      <div class="text-[11px]">
        As informações apresentadas neste site são apenas para informação aos
        profissionais de saúde relacionados com o programa RAStrear.
      </div>
    </div>
  </footer>
</template>

<script>
import ALogo from "@/components/atoms/Logo.vue";
import OContact from "./Contact.vue";
import BackToTop from "../molecules/BackToTop.vue";
import { inject } from "vue";

export default {
  name: "Footer",
  props: {
    contact: {
      required: false,
      default: true,
      type: Boolean,
    },
  },
  components: {
    ALogo,
    OContact,
    BackToTop,
  },
  setup() {
    const privacyPoliceLink = inject("privacyPoliceLink");

    return { privacyPoliceLink };
  },
};
</script>

<style lang="postcss">
.o-footer {
  &__info {
    margin-top: -5.3rem;
  }
}
</style>
