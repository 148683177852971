import $ from "jquery";

const useLockScroll = () => {
  const scrollLockedClass = "scroll-locked";

  function lock() {
    $("html, body").addClass(scrollLockedClass).css({ overflow: "hidden" });
  }

  function unlock() {
    $("html, body").removeClass(scrollLockedClass).css({ overflow: "initial" });
  }

  return {
    lock,
    locked: $("html, body").hasClass(scrollLockedClass),
    unlock,
  };
};

export default useLockScroll;
