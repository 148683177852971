<template>
  <div class="m-listbox text-white relative">
    <div
      v-html="selectedItemTitle"
      class="
        py-4
        px-4
        rounded
        before:w-12
        before:h-12
        before:rounded
        before:right-1
        before:absolute
        before:top-1
        before:bg-white/50
        after:absolute
        after:top-[22px]
        after:right-[18px]
        after:w-0
        after:h-0
        after:border-solid
        after:border-r-[10px]
        after:border-l-[10px]
        after:border-r-[transparent]
        after:border-l-[transparent]
      "
      :class="`${selectedItemColor}${
        !open
          ? ' after:border-t-[14px] after:border-b-0 after:border-t-white after:border-b-[transparent]'
          : ' after:border-b-[14px] after:border-t-0 after:border-b-white after:border-t-[transparent] after:!top-5'
      }`"
      @click="toggleListbox"
    />
    <ul class="absolute left-0 w-full" :class="{ block: open, hidden: !open }">
      <li
        v-for="{ action, color, id, link, title } in filteredItems"
        v-html="title"
        :class="`${id} ${color}`"
        :key="id"
        @click.prevent="() => onChangeItem({ action, color, id, link, title })"
        class="py-4 px-4 rounded"
      />
    </ul>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "ListBox",
  props: {
    activeItem: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    onSelecItem: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const open = ref(false);
    const activeItemObj = props.items.find(({ id }) => id === props.activeItem);
    const selectedItemColor = ref(activeItemObj.color);
    const selectedItemTitle = ref(activeItemObj.title);
    const filteredItems = ref(
      props.items.filter(({ id }) => id !== props.activeItem)
    );

    function toggleListbox() {
      open.value = !open.value;
    }

    function onChangeItem({ action, color, id, link, title }) {
      if (action) {
        toggleListbox();

        return link();
      }

      selectedItemColor.value = color;
      selectedItemTitle.value = title;
      filteredItems.value = props.items.filter(
        ({ id: itemId }) => id !== itemId
      );

      toggleListbox();
      props.onSelecItem(id);
      router.push(link);
    }

    return {
      filteredItems,
      onChangeItem,
      open,
      selectedItemTitle,
      selectedItemColor,
      toggleListbox,
    };
  },
};
</script>

<style lang="postcss">
.m-listbox {
  br {
    @apply hidden;
  }
}
</style>
