<template>
  <teleport to="body">
    <div
      :class="{
        'opacity-100 pointer-events-auto before:pointer-events-auto': open,
        'opacity-0 pointer-events-none before:pointer-events-none': !open,
      }"
      class="m-dialog top-0 left-0 fixed w-screen z-40 transition-all duration-200 ease-in before:bg-[#244170] before:-z-1 before:opacity-90 before:block before:fixed before:w-full before:h-full before:inset-x-0 before:bottom-0 before:transition before:duration-200 before:ease-in"
    >
      <dialog
        class="m-dialog__dialog rounded-lg bg-white shadow-lg w-4/5 max-w-full m-auto text-center text-primary p-6 text-sm mt-32 relative lg:w-3/5 lg:max-w-lg xl:w-2/5"
        :open="open"
      >
        <button
          class="-right-8 -top-4 absolute"
          @click="closeDialog"
          title="Fechar"
          type="button"
        >
          <inline-svg
            aria-label="Fechar"
            class="m-dialog__dialog__close h-12 pointer-events-none"
            :src="require('@/assets/close-green.svg')"
            title="Fechar"
          />
        </button>
        <slot />
        <slot name="footer" />
      </dialog>
    </div>
  </teleport>
</template>

<script>
import useLockScroll from "@/composables/useLockScroll";
import { inject, onMounted, watchEffect } from "vue";

export default {
  name: "Dialog",
  props: {
    onClose: {
      default: () => {},
      required: false,
      type: Function,
    },
    open: {
      default: false,
      required: false,
      type: Boolean,
    },
  },
  setup(props) {
    const isPrerendering = inject("isPrerendering");
    const scroll = useLockScroll();
    const closeDialog = () => props.onClose();

    onMounted(() => {
      if (!isPrerendering.value && props.open) {
        scroll.lock();
      } else {
        scroll.unlock();
      }
    });

    watchEffect(() => ({
      open: (value) => {
        if (value) {
          return scroll.lock();
        }

        scroll.unlock();
      },
    }));

    return { closeDialog };
  },
};
</script>
