import { provide, ref } from "vue";
import { useRoute } from "vue-router";

const useInternalNav = ({ menuColor, menuItems, menuTextColor }) => {
  const route = useRoute();
  const activeMenuItem = ref(
    !route.meta.sub
      ? route.name.toLowerCase()
      : route.matched[1].name.toLowerCase()
  );

  function onChangeMenuItem(menuItemId) {
    activeMenuItem.value = menuItemId;
  }

  provide("activeMenuItem", activeMenuItem);
  provide("menuColor", menuColor);
  provide("menuItems", menuItems);
  provide("menuTextColor", menuTextColor);
  provide("onChangeMenuItem", onChangeMenuItem);
};

export default useInternalNav;
