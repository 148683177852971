import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import InlineSvg from "vue-inline-svg";
import VueCookies from "vue3-cookies";
import "./assets/tailwind.css";

const app = createApp(App);

app.use(store).use(router).use(VueCookies, {
  expireTimes: "120d",
});
app.component("inline-svg", InlineSvg);
app.mount("#app");
