<template>
  <div id="internal-nav" class="relative z-20">
    <ListBox
      class="w-[86%] max-w-sm mx-auto lg:hidden"
      :activeItem="activeMenuItem"
      :items="menuItems"
      :onSelecItem="onChangeMenuItem"
    />
    <div class="lg:px-6">
      <Tabs
        class="m-pages-tabs hidden lg:block"
        :class="`${activeMenuItem} ${menuColor}`"
        :activeTab="activeMenuItem"
        :activeTextClass="menuTextColor"
        :onChangeTab="onChangeMenuItem"
        :tabs="menuItems"
      />
    </div>
  </div>
</template>

<script>
import ListBox from "@/components/molecules/ListBox.vue";
import Tabs from "@/components/molecules/Tabs.vue";
import { inject } from "vue";

export default {
  name: "InternalNav",
  components: { ListBox, Tabs },
  setup() {
    const activeMenuItem = inject("activeMenuItem");
    const menuColor = inject("menuColor");
    const menuItems = inject("menuItems");
    const menuTextColor = inject("menuTextColor");
    const onChangeMenuItem = inject("onChangeMenuItem");

    return {
      activeMenuItem,
      menuColor,
      menuItems,
      menuTextColor,
      onChangeMenuItem,
    };
  },
};
</script>
