import { createRouter, createWebHistory } from "vue-router";
import $ from "jquery";
import useLockScroll from "@/composables/useLockScroll";
import { routes } from "./routes";

const prefix = "Programa RAStrear";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }

    if (to.meta.noTop) {
      return;
    }

    return {
      top: 0,
    };
  },
});

router.beforeEach((to, _from, next) => {
  const scroll = useLockScroll();

  if (to.meta.title) {
    $("title").text(`${prefix} | ${to.meta.title}`);
  }

  if (_from.name && scroll.locked) {
    useLockScroll().unlock();
  }

  next();
});

export default router;
